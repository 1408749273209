import once from "@drupal/once";
import Swiper from 'swiper';
import { EffectFade, Autoplay, Navigation } from "swiper/modules"
import { ScrollTrigger } from "gsap/all";

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export default class Reviews  {

  constructor(selector) {
    const elements = once('reviews', selector);
    elements.forEach(element => {
      const swiper = new Swiper(element.querySelector('.swiper-reviews'), {
        modules: [EffectFade, Autoplay, Navigation],
        autoplay:{
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        effect: "fade",
        fadeEffect: { crossFade: true },
        slidesPerView: 1,
        speed: 750,
        loop : true,
        autoHeight: true,
        keyboard : {
          enabled: true,
          onlyInViewport: true,
        }
      });

      swiper.on('transitionEnd', () =>{
        ScrollTrigger.refresh();
      });
    });


  }

}
