export default class NavMobile {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    if (this.body.dataset.navMobileInitialized) {
      return;
    }

    this.header = document.querySelector('.header');
    this.nav = document.querySelector('.navigation--header')
    this.body.dataset.navMobileInitialized = true;
    this.triggerButton = document.querySelector('.navigation--trigger');
    this.triggers = document.querySelectorAll('.navigation--trigger-overlay');
    this.active = false;

    if (this.nav && this.triggers) {
      this.body.addEventListener('click', (event) => {
        if (this.active) {
          // setTimeout(() => {
            this.html.classList.remove('overflow-hidden');
            this.header.classList.remove('navigation__active');
            this.nav.classList.replace('opacity-100', 'opacity-0');
            this.nav.classList.replace('visible', 'invisible');
            this.triggerButton.classList.replace('text-white', 'text-theme-navigation');
          // }, 500);
          this.active = false;
        }
        else {
          let triggerHit = false
          this.triggers.forEach((trigger, key) => {
            if (event.target == trigger) {
              triggerHit = true;
            }
          });

          if (triggerHit) {
            this.active = true;
            this.html.classList.add('overflow-hidden');
            this.header.classList.add('navigation__active');
            this.nav.classList.replace('opacity-0', 'opacity-100');
            this.nav.classList.replace('invisible', 'visible');
            this.triggerButton.classList.replace('text-theme-navigation', 'text-white');
          }
        }

        this.triggerButton.setAttribute('aria-pressed', this.active);
        this.triggerButton.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
        this.nav.setAttribute('aria-hidden', !this.active);
      });

    }
  }

}
