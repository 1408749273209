import once from "@drupal/once";
import Swiper from 'swiper';
import { EffectFade } from "swiper/modules"

import "swiper/css"
import "swiper/css/effect-fade"

export default class HealthQuiz {
  constructor(selector) {
    const elements = once('quiz', selector);
    elements.forEach(element => {
      this.paginationCurrent = element.querySelector('.swiper-pagination-current');
      this.triggerYes = element.querySelector('.quiz-trigger-yes');
      this.triggerNo = element.querySelector('.quiz-trigger-no');
      this.quiz = element.querySelector('.quiz-quiz');
      this.result = element.querySelector('.quiz-result');
      this.resultPoints = element.querySelector('.quiz-result-points');
      this.points = 0;
      this.finishable = false;

      this.swiper = new Swiper(element.querySelector('.swiper-quiz'), {
        modules: [EffectFade],
        slidesPerView: 1,
        effect: "fade",
        fadeEffect: { crossFade: true },
        slidesPerView: 1,
        speed: 750,
        loop: false,
        keyboard: {
          enabled: false,
        },
      });

      this.swiper.on('activeIndexChange', (swiper) => {
        this.paginationCurrent.innerText = swiper.activeIndex + 1;
        if (this.swiper.activeIndex == this.swiper.slides.length - 1) {
          this.finishable = true
        }
      });


      this.triggerYes.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.points += 1;
        this.handleClick();
      });

      this.triggerNo.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.handleClick();
      });
    });
  }


  handleClick() {
    this.resultPoints.innerText = this.points;

    if (this.finishable) {
      this.quiz.classList.remove('opacity-100');
      this.quiz.classList.add('opacity-0');
      setTimeout(() => {
        this.quiz.classList.add('hidden');
        this.result.classList.remove('hidden');
        setTimeout(() => {
          this.result.classList.remove('opacity-0');
          this.result.classList.add('opacity-100');
        }, 200);
      }, 200);
    }
    else {
      this.swiper.slideNext();
    }
  }

}
